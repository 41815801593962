$ const { get } = require('@ebay/retriever');
$ const {label, options} = input.model || {};
$ const labelTextSpan = get(label, 'textSpans.0.text', '')
$ const sortForm = input.sortForm || {}
<div class="sort">
    <ebay-icon-button
        class="sort__icon"
        on-click('handleClick')
        aria-label=get(label, 'textSpans.0.accessibilityText', '')>
        <ebay-sort-12-icon/> ${labelTextSpan}
    </ebay-icon-button>
    <drawer
        a11y-close-text=get(sortForm, 'closeIcon.action.accessibilityText', '')
        a11y-minimize-text=get(sortForm, 'minimizeA11lyText', '')
        a11y-maximize-text=get(sortForm, 'maximizeA11lyText', '')
        open=!state.hideSort
        on-close('hideSortOptions')>
        <@header>
            <ux-textualdisplay model=get(sortForm, 'title', {}) />
        </@header>
        <@body>
            <div class="sort__drawer-body">
                <ebay-listbox name=labelTextSpan on-change('handleValueEvent')>
                    <for|option| of=(options || [])>
                        <@option
                            text=get(option, 'label.textSpans.0.text')
                            value=get(option, 'paramValue')
                            selected=(state.value ? (state.value == option.paramValue) :  option.selected) />
                    </for>
                </ebay-listbox>
            </div>
        </@body>
    </drawer>
    <!-- SVG not loading through the ebay UI bcoz of shadow dom implemenation -->
    <div hidden aria-hidden="true">
        <svg>
            <symbol viewBox="0 0 16 16" id="icon-sort-12">
                <path d="M4 3.001a.75.75 0 0 1 .75.75v6.544l.94-1.046a.75.75 0 1 1 1.116 1.003L4.569 12.74a.75.75 0 0 1-1.137 0l-2.24-2.487a.75.75 0 1 1 1.115-1.004l.943 1.047V3.752a.75.75 0 0 1 .75-.75Zm6.308 3.755a.75.75 0 1 1-1.116-1.002l2.25-2.505a.75.75 0 0 1 1.18.081l2.184 2.418a.75.75 0 1 1-1.112 1.006L12.75 5.71v6.541a.75.75 0 0 1-1.5 0V5.707l-.942 1.05Z"></path>
            </symbol>
            <symbol viewBox="0 0 16 16" id="icon-tick-16">
                <path fill-rule="evenodd" d="M13.707 5.707a1 1 0 0 0-1.414-1.414L6 10.586 3.707 8.293a1 1 0 0 0-1.414 1.414l3 3a1 1 0 0 0 1.414 0l7-7Z" clip-rule="evenodd"></path>
            </symbol>
        </svg>
    </div>
</div>
