$ {
    const model = input.model || {};
    const {
        label,
        cards
    } = model;
};

<div class="card-tile">
    <div class="card-tile__label"><ux-textualdisplay model=label/></div>
    <div class="card-tile__cards">
            <for|card, index| of=cards>
                <div class="card-tile__card">
                    <div class="card-tile__card-left">
                        <span>
                            ${index + 1}
                        </span>
                    </div>
                    <div class="card-tile__card-right">
                        <div class="card-tile__card__title">
                            <ux-textualdisplay model=card.title/>
                        </div>
                        <for |detail| of=card.details>
                            <div class="card-tile__card__details">
                                <ux-textualdisplay model=detail/>
                            </div>
                        </for>
                    </div>
                </div>
            </for>
    </div>
</div>