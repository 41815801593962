module.exports = class {
    onCreate(input) {
        this.state = {
            showDrawer: input.showDrawer
        };
    }

    handleChange(e) {
      this.emit('installer-click', this.input.model[e.index]);
    }

    onUpdate() {
        this.scrollIntoView();
    }

    onMount() {
        this.scrollIntoView();
    }

    scrollIntoView() {
        const selectedInstaller = this.input.selectedInstaller;

        if(selectedInstaller && selectedInstaller.hasOwnProperty('paramValue')) {
            const installerCard = this.getComponent(`installer-card-${selectedInstaller.paramValue}`);
            const selectedEl = installerCard && installerCard.getEl();

            if (selectedEl && selectedEl.scrollIntoView) {
                setTimeout(
                    () => selectedEl.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'start' }),
                    500
                );
            }
        }
    }
};
