import { get } from '@ebay/retriever';

$ const closeAction = get(input, 'model.action', {});

<div class="vas-header">
    <${input.headerLeft }/>
    <${input.title }/>
    <ebay-icon-button
        id="vas-header-close-icon"
        class="vas-header__close"
        aria-label=closeAction.accessibilityText
        on-click('emit', 'close', closeAction.trackingList)>
        <ebay-close-16-icon/>
        <!-- Debug :- SVG not loading through the ebay UI-->
        <div hidden aria-hidden="true">
            <svg viewBox="0 0 18 18" id="icon-close-16">
                <path d="M10.41 9l7.294-7.287A1.004 1.004 0 0016.285.294L9 7.59 1.715.294a1.002 1.002 0 10-1.42 1.42l7.296 7.285-7.295 7.286a1 1 0 000 1.42 1 1 0 001.419 0L9 10.407l7.285 7.296a1 1 0 001.42 0 1 1 0 000-1.419l-7.296-7.286z"></path>
            </svg>
        </div>
    </ebay-icon-button>
</div>
