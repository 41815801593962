module.exports = class {
    onCreate() {
        this.state = {
            hideSort: true,
            value: ''
        };
    }

    handleClick() {
        this.state.hideSort = false;
    }

    handleValueEvent({ selected }) {
        this.hideSortOptions();
        const selectedValue = selected[0];

        this.state.value = selectedValue;

        if(selectedValue) {
            this.emit('process-sort', {sortBy: selectedValue})
        }
    }

    hideSortOptions() {
        this.state.hideSort = true;
    }
}
