$ const { get } = require('@ebay/retriever');

$ const model = input.model || {};
$ const {
    error,
    value
} = state || {};

<div class="radius">
    <div class="radius__radius-field">
        <ebay-select name="radius"
            floating-label=get(model, 'label.textSpans.0.text', '')
            aria-invalid=(error && 'true')
            on-change('handleValueEvent', 'value-change')>
            <@option value=null text=get(model, 'label.textSpans.0.text', '') />
            <for|option| of=(model.options || [])>
                <@option
                    selected=(value == option.value)
                    value=option.value
                    text=option.text />
            </for>
        </ebay-select>
        <p hidden=(!error) class="radius__error-message">
            <ux-textualdisplay model=get(model, 'validations.0.message.additionalText.0', {}) />
        </p>
    </div>
</div>
