
$ const { get } = require("@ebay/retriever");

$ {
    const model = input.model || {};
    const appointmentModule = get(model, 'VAS_APPOINTMENT_SCHEDULE', {});
    const layerCTA = get(model, 'VAS_CTA', {});
    const itemCard = get(model, 'VAS_ITEM_CARD_MINIMAL.card', {});
    const appointmentTitleSection = get(appointmentModule, 'appointmentDescription', {});
    const calendarInput = get(appointmentModule, 'calendar', {});
    const timeSlotModel = get(appointmentModule, 'time' , {});
    const slotEntries = get(appointmentModule, 'time.entries', []);
    const providerModule = get(model, 'VAS_PROVIDER', {});
}

<if(!appointmentModule.isEmpty)>
    <div class="appointment">
        <item-card model=itemCard />
        <div class="appointment__date">
            <section model=appointmentTitleSection/>
            <div class="appointment__date-calendar">
                <ebay-calendar
                    ...calendarInput
                    on-select("onDateSelected")
                    on-month-change("emit", "month")
                    on-focus("emit", "focus")
                    selected=state.selectedDate
                />
            </div>

            <timeslot model=timeSlotModel on-select-slot('handleTimeSlot') enableSlots=state.enableSlots selectedSlot=state.selectedSlot/>

            <if(!providerModule.__isEmpty)>
                <provider model=providerModule/>
            </if>
            
        </div>
    </div>
</if>
