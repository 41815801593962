import data from "./__tests__/fixtures/intro.json";
$ {
    // const model = input.model || {};
    const model = data;
    const {
        VAS_INTRO,
        VAS_IMAGE: {
            URL: imageUrl,
            title: imageTitle
        } = {},
        VAS_CTA
    } = model;    
    const closeIcon = {};
    const layerStateCta = VAS_CTA;
};
<div class="parts-installation">
    <!-- Header Section Common for Installer Screen and Appointment Screen-->
    <header model=closeIcon on-close("dialogClose")>
        <@title>
           <span>&nbsp;</span>
        </@title>
    </header>
    <if(VAS_INTRO)>
        <introduction  model={}>
            <@topContent>
                <div class="introduction__top-content">
                    <img src=imageUrl alt=imageTitle />
                </div>
            </@topContent>
            <@bottomContent>
                <div class="introduction__bottom-content">
                    <card-tile model=VAS_INTRO />
                </div>            
            </@bottomContent>
            <@footer>
                <div class="introduction__footer">
                <ctas
                    model=layerStateCta
                    isBusy=false />
                </div>
            </@footer>
        </introduction>
    </if>
</div>