/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
const pubsub = require('raptor-pubsub');
const { get } = require('@ebay/retriever');

// no add item selected when user land on warranty interstitial overlay
module.exports = class {
  onCreate(input) {
    this.vasForms = get(input, 'model.vasForm', []);
    this.isMobile = get(input, 'options.global.isMobile', false);
    this.defaultTitle = get(input, 'model.title', {});
    this.pluralTitle = get(input, 'model.additionalParam.alternateTitle', {});
    this.eligibleVASTypes = get(input, 'viModel.VAS_META.eligibleVASTypes', []);
    this.quantity = get(input, 'quantity', 1);
    input.showErrorState = this.checkIfFreight(this.eligibleVASTypes) && this.isInvalidQuantityForFreight(this.quantity);
    this.isSpokeLayer = get(input, 'isSpoke', true);
    const interstitialCta = get(input, 'cta', {});
    this.ctas = interstitialCta.__isEmpty ?  get(input, 'viModel.VAS_CTA', {}) : interstitialCta;
    this.vasContainerElem = null;
    input.ctas = this.ctas;

    // Addded this to support _sp tag
    this.trksid = get(input, 'modelConfig.trksid', '');
    const qualifyingVariantFromGlobal = get(input, 'options.global.qualifyingVariant', '');
    const qualifyingVariant = input.qualifyingVariant || qualifyingVariantFromGlobal;
    const hubAddonsSelectionMap = input.addonsSelectionMap;
    const entriesMap = this.getEntriesMapForSpoke(this.vasForms, qualifyingVariant);
    // This defaultMap is used for merging with addonselectionmap to find all the selcted value
    this.defaultMap = this.buildDefaultMap(entriesMap);
    const valuesMap = this.fillMap(this.vasForms);
    const addonsSelectionMap = this.getAddonsSelectionMap(this.defaultMap, hubAddonsSelectionMap);
    const isMultiAddon = get(input, 'isMultiAddon', false);

    this.state = {
      valuesMap: valuesMap,
      entriesMap: entriesMap,
      addonsSelectionMap: addonsSelectionMap,
      qualifyingVariant: qualifyingVariant, // msku variation Id
      channelId: input.channelId,
      isBusy: false,
      isMultiAddon: isMultiAddon,
      title: isMultiAddon ? this.pluralTitle : this.defaultTitle
    };

    this.updateCTAThisContext = this.updateCTA.bind(this);
  }

  updateCTA(event) {
    // In some browsers, back button click shows opened layer in VI page.
    // In order to show enabled CTA in that case, Enabling the CTA when the page is loaded with layer
    if (event.persisted) {
      if (this && this.setState) {
        this.setState({isBusy: false});
      }
    }    
  }

  fillMap(vasForms) {
    const t = this;
    const addonMap = new Map();
    if (Array.isArray(vasForms) && vasForms.length > 0) {
      vasForms.forEach((vasForm) => {
        const valueMap = new Map();
        const addonType = get(vasForm, 'entries[0].paramKey', '');
        const addonVasId = get(vasForm, 'entries[0].paramValue', '');
        if (addonType) {
          valueMap.set('heading', vasForm.heading);
          valueMap.set('subHeading', vasForm.subHeading);
          valueMap.set('vasIndex', addonVasId);
        }
        for (const optionEntry of vasForm.entries) {
          valueMap.set(optionEntry.paramValue, optionEntry);
        }
        addonMap.set(addonType, valueMap);
      });
    }
    return addonMap;
  }

  /**
 * This method tell whether the given addon option is eligible for the given selected variation Id
 * @param {*} entry Addon Option Entry
 * @param {*} qualifyingVariant Selected VariationId
 */
  isAddonOptionEligibleForVariationId(entry, qualifyingVariant) {
    if (qualifyingVariant) {
      const supportedVarIds = get(entry, 'filter.values', []);
      if (supportedVarIds.length === 0 || !supportedVarIds.includes(qualifyingVariant)) {
        return false;
      } 
    }
    return true;
  }

/**
 * This method tell whether the given entry is eligible to show in Spoke/Nudge
 * @param {*} entry 
 * @param {*} qualifyingVariant 
 */
isEntryEligibleForSpoke(entry, qualifyingVariant) {
    // If qualifyingvariant is present, Look at filter
    if (qualifyingVariant) {
      return this.isAddonOptionEligibleForVariationId(entry, qualifyingVariant);
    }
    return entry.addonForDefault;
  }

  getEntriesMapForSpoke(vasForms, qualifyingVariant) {
    const entriesMap = new Map();
    if (Array.isArray(vasForms) && vasForms.length > 0) {
      vasForms.forEach((vasForm) => {
        if (Array.isArray(vasForm.entries) && vasForm.entries.length > 0) {
          for (const optionEntry of vasForm.entries) {
            if (optionEntry && this.isEntryEligibleForSpoke(optionEntry, qualifyingVariant)) {
              if (!entriesMap.has(optionEntry.paramKey)) {
                const arr = [];
                arr.push(optionEntry);
                entriesMap.set(optionEntry.paramKey, arr);
              } else {
                const arr = entriesMap.get(optionEntry.paramKey);
                arr.push(optionEntry);
              }
            }
          }
        }
      });
    }
    return entriesMap;
  }

  buildDefaultMap(entriesMap) {
    const defaultSelectionMap = new Map();
    if(typeof entriesMap !== undefined && entriesMap) {
      for (const [key, entries] of entriesMap) {
        defaultSelectionMap.set(key, '-1');
      }
    }
    return defaultSelectionMap;
  }

  getAddonsSelectionMap(defaultMap, hubSelectionMap) {
    const addonsSelectionMap = new Map();
    if(typeof defaultMap !== undefined && defaultMap) {
      for (const [key, value] of defaultMap) {
        addonsSelectionMap.set(key, hubSelectionMap.get(key) || value);
      }
    }
    return addonsSelectionMap;
  }

  getActionFromCta(ctas) {
    const defaultAction = get(ctas, 'sections[0].dataItems[0].action', {});
    if (!defaultAction.__isEmpty) {
      return defaultAction;
    }
    return null;
  }

  // Add EMPTY_NUDGE err tag
  addEmptyLayerErrorTag(trackingList) {
    if (Array.isArray(trackingList) && trackingList.length > 0) {
      trackingList.forEach((entry) => {
        if (entry && entry.eventProperty) {
          entry.eventProperty.err = "EMPTY_NUDGE";
         }
       });
    }
  }

  doImpression(err) {
    // Spoke/Nudge Impression tracking
    const trackingList = get(this.input, 'model.meta.trackingList', []);
    if (Array.isArray(trackingList) && trackingList.length > 0) {
      if (err) {
        this.addEmptyLayerErrorTag(trackingList);
      }
      pubsub.channel(this.input.channelId).emit('tracking', trackingList);
    } 
  }

  onMount() {
    this.vasContainerElem = document.getElementById('vas-container');
    const emptyCase = (!this.isSpokeLayer) && (this.state.entriesMap && this.state.entriesMap.size === 0);
    // If No entries available to render in Nudge layer, Do redirection otherwise it will show empty layer
    this.doImpression(emptyCase);
    if(emptyCase) {
      // Pick defaultAction url from intersticial CTA action params
      const defaultActionUrl = get(this.getActionFromCta(this.ctas), 'params.defaultActionUrl', '');
      if(defaultActionUrl) {
        this.doRedirection(defaultActionUrl, this.trksid, this.vasContainerElem);
      }
    } else {
      // Listen pageshow event to know the case whether the layer is shown up from browser cache.
      window.addEventListener('pageshow', this.updateCTAThisContext);
    }
  }

  onDestroy() {
    window.removeEventListener('pageshow', this.updateCTAThisContext);
  }

  broadcastLayerCloseEvent(channelId, isSpokeLayer) {
    if (isSpokeLayer) {
      pubsub.channel(channelId).emit('VAS_CLOSE_SPOKE');
    } else {
      pubsub.channel(channelId).emit('VAS_CLOSE_INTERSTITIAL');
    }
  }

  broadcastTrackingEvent(channelId, action) {
    if (action && action.trackingList) {
      pubsub.channel(channelId).emit('tracking', action.trackingList);
    }
  }

  onCloseButtonClick(action) {
    this.broadcastLayerCloseEvent(this.state.channelId, this.isSpokeLayer);
    this.broadcastTrackingEvent(this.state.channelId, action);
  }

  onRadioSelected(addonEntry) {
    if (addonEntry) {
      this.state.addonsSelectionMap.set(addonEntry.paramKey, addonEntry.paramValue);
      this.setStateDirty('addonsSelectionMap');
    }
  }

  removeUrlSid(url) {
    if (!url) {
        return '';
    }
    const regexForTrksidParam = new RegExp("[?&]_trksid=([^&]+).*$");
    const oldSid = url.match(regexForTrksidParam);
    if (oldSid && oldSid.length > 1) {// replace if found match
        const sidParam = `_trksid=${oldSid[1]}`;
        return url.replace(`&${sidParam}`, '').replace(sidParam, '');
    }
    return url;
  }

  getCommonVasSpAnc(rootEl) {
    let link = document.getElementById('vas-sp-nav');
    if (!link) {
      link = document.createElement('a');
      link.id = 'vas-sp-nav';
      link.setAttribute('style', 'display:none');
      if (rootEl) {
          rootEl.append(link);
      } else {
          document.body.append(link);
      }
    }
    return link;
  }

  doRedirection(destinationUrl, trksid, rootEl) {
    if (!destinationUrl) return;
    const resultUrl = this.removeUrlSid(destinationUrl);
    const link = this.getCommonVasSpAnc(rootEl);
    if (link) {
      link.setAttribute('tabindex', -1);
      link.setAttribute('href', resultUrl);
      if (trksid) {
        link.setAttribute('_sp', trksid);
      }
      link.click();
    }
  }

  handleShopActionResponse(res, err, requestAction) {
    if (err || (res && res.modules && res.modules.STATUS_MESSAGE)) {
      this.doRedirection(requestAction.params.defaultActionUrl || requestAction.params.ru, this.trksid, this.vasContainerElem);
    }

    if (res && res.meta && res.meta.screenFlowDestination) {
      const destinationUrl = res && res.meta && res.meta.screenFlowDestination && res.meta.screenFlowDestination.URL;
      if (destinationUrl) {
        this.doRedirection(destinationUrl, this.trksid, this.vasContainerElem);
        return;
      }
    }
  }

  getShopactionsCsrfToken(action, global, isMobile) {
    if (action && action.clientPresentationMetadata) {
      const csrfTokenList = isMobile ?  get(global, 'csrfTokens', {}) : get(global, 'csrfTokenList', {});
      if (csrfTokenList && action.clientPresentationMetadata.csrfApp && action.clientPresentationMetadata.csrfCommand) {
        return get(csrfTokenList, `${action.clientPresentationMetadata.csrfApp}.${  action.clientPresentationMetadata.csrfCommand}`, "");
      }
    }
    return "";
  }

  getUpdatedPostParams(action, entriesMap, selectionMap, srt) {
    if(typeof action === undefined || !action || typeof selectionMap === undefined || !selectionMap || typeof entriesMap === undefined || !entriesMap) {
      return {};
    }
    const postBody = action.params;
    const selectedServices = {};
    const eligibleServices = {};
    if (entriesMap.size > 0) {
      for (const [key, entries] of entriesMap) {
        if (selectionMap && selectionMap.size > 0 && selectionMap.has(key)) {
          selectedServices[key]=[selectionMap.get(key)];
        } else{
          selectedServices[key]=["-1"];
        }
        eligibleServices[key]=[""];
      }
    }
    postBody.selectedServices = selectedServices;
    postBody.eligibleServices = eligibleServices;
    if (srt) {
      postBody.srt = srt;
    }
    if (!(postBody.variationId && Number(postBody.variationId) > 0)) {
        postBody.variationId = null;
    }
    return postBody;
  }

  callShopActionView(action, callback) {
    if (typeof action === undefined || !action || !action.URL) {
      return;
    }
    const srt = this.getShopactionsCsrfToken(action, get(this, 'input.options.global', {}), this.isMobile);
    const postBody = this.getUpdatedPostParams(action, this.state.entriesMap, this.state.addonsSelectionMap, srt);
    const postUrl = `${action.URL }srt=${srt}`;

    fetch(postUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      },
      mode: 'no-cors',
      body: JSON.stringify(postBody)
      }).then(response => {
          if(!response.ok){
            callback(null, {}, action);
          }
          return response.json();
      }).then(data => {
          if (data) {
            callback(data, null, action);
          }
      }).catch(() => {
          callback(null, {}, action);
      })
  }

  // Get the selected addon details as string for tracking purpose.
  getSelectedAddonsStr(defaultMap, addonsSelectionMap) {
    let selectedAddonsStr = '';
    if(typeof defaultMap !== undefined && defaultMap && typeof addonsSelectionMap !== undefined && addonsSelectionMap) {
      for (const [key, entries] of defaultMap) {
        selectedAddonsStr = `${selectedAddonsStr}${selectedAddonsStr? ',': ''}${key}:${addonsSelectionMap.has(key) ? addonsSelectionMap.get(key): '-1'}`
      }
    }
    return selectedAddonsStr;
  }

  // This method extract all the selected addons details.
  getCtaClickTrackingList(trackingList, defaultMap, addonsSelectionMap) {
    if (Array.isArray(trackingList) && trackingList.length > 0) {
      trackingList.forEach((entry) => {
        if (entry && entry.eventProperty) {
           entry.eventProperty.VAS_FE_SELECTED_ADDONS = this.getSelectedAddonsStr(defaultMap, addonsSelectionMap);
         }
       });
    }
    return trackingList;
  }

  onCtaClick(action) {
    if (action && action.trackingList) {
      pubsub.channel(this.state.channelId).emit('tracking', this.getCtaClickTrackingList(action.trackingList, this.defaultMap, this.state.addonsSelectionMap));
    }
    if (this.isSpokeLayer) {
      pubsub.channel(this.state.channelId).emit('VAS_CONFIRM_SELECTION_SPOKE', this.state.addonsSelectionMap);
      pubsub.channel(this.state.channelId).emit('VAS_CLOSE_SPOKE');
    } else {
      if(!this.state.isBusy) {
        this.state.isBusy = true;
        this.callShopActionView(action, this.handleShopActionResponse.bind(this));
      }
    }
  }

  handleActionClick(action) {
    if (!action) {
      return;
    }
    if (action.trackingList) {
      pubsub.channel(this.state.channelId).emit('tracking', action.trackingList);
    }
  }

  isInvalidQuantityForFreight(quantity) {
    return quantity > 1;
  }

  checkIfFreight(eligibleVASTypes) {
    return eligibleVASTypes && eligibleVASTypes.length > 0 && eligibleVASTypes.includes('FREIGHT');
  }

};
