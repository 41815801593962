// Provides pre-defined collators for vasForm data
// @usage
// const data = getVasFormData(500);
// const dataSortedByDistanceDescending = data.sort(VasSort.DISTANCE_DESC)
// const dataSortedByPrice = data.sort(VasSort.PRICE)
// ...

const { get } = require('@ebay/retriever');

const VasValueNames = {
    DISTANCE: 'distance',
    NAME: 'name',
    PRICE: 'priceValue',
    TIME: 'dropOffDateTimeSeq'
};

const html = (typeof document !== 'undefined') && document.querySelector('html');
const lang = get(html, 'attributes.lang.nodeValue') || 'en';

// create collator instances for numeric and alpha-numeric strings
const collator = new Intl.Collator(lang);
const numericCollator = new Intl.Collator(lang, { numeric: true });

/**
 * @returns {number} negative goes before, 0 is equal, positive goes after
 */
const collate = (a, b, prop) => {
    const ap = get(a, 'vasValues', {})[prop];
    const bp = get(b, 'vasValues', {})[prop];

    return (isNaN(parseFloat(ap)) ? collator : numericCollator).compare(ap, bp);
};

 /**
  * sort by distance, price, name, time
  */
 const distanceAsc = (a, b) => (
    collate(a, b, VasValueNames.DISTANCE) ||
    collate(a, b, VasValueNames.PRICE) ||
    collate(a, b, VasValueNames.NAME) ||
    collate(a, b, VasValueNames.TIME)
);
/**
 * sort by distance descending, price, name, time
 */
const distanceDesc = (a, b) => (
    -collate(a, b, VasValueNames.DISTANCE) ||
    collate(a, b, VasValueNames.PRICE) ||
    collate(a, b, VasValueNames.NAME) ||
    collate(a, b, VasValueNames.TIME)
);
/**
 * sort by price, distance, name, time
 */
const priceAsc = (a, b) => (
    collate(a, b, VasValueNames.PRICE) ||
    collate(a, b, VasValueNames.DISTANCE) ||
    collate(a, b, VasValueNames.NAME) ||
    collate(a, b, VasValueNames.TIME)
);
/**
 * Sort by price descending, distance, name, time
 */
const priceDesc = (a, b) => (
    -collate(a, b, VasValueNames.PRICE) ||
    collate(a, b, VasValueNames.DISTANCE) ||
    collate(a, b, VasValueNames.NAME) ||
    collate(a, b, VasValueNames.TIME)
);
/**
 * sort by name, price, distance, time
 */
const nameAsc = (a, b) => (
    collate(a, b, VasValueNames.NAME) ||
    collate(a, b, VasValueNames.PRICE) ||
    collate(a, b, VasValueNames.DISTANCE) ||
    collate(a, b, VasValueNames.TIME)
);
/**
 * sort by name descending, price, distance, time
 */
const nameDesc = (a, b) => (
    -collate(a, b, VasValueNames.NAME) ||
    collate(a, b, VasValueNames.PRICE) ||
    collate(a, b, VasValueNames.DISTANCE) ||
    collate(a, b, VasValueNames.TIME)
);

/**
 * sort by time, price, distance, name
 */
const timeAsc = (a, b) => (
    collate(a, b, VasValueNames.TIME) ||
    collate(a, b, VasValueNames.PRICE) ||
    collate(a, b, VasValueNames.DISTANCE) ||
    collate(a, b, VasValueNames.NAME)
);
/**
 * sort by time, price, distance, name
 */
const timeDesc = (a, b) => (
    -collate(a, b, VasValueNames.TIME) ||
    collate(a, b, VasValueNames.PRICE) ||
    collate(a, b, VasValueNames.DISTANCE) ||
    collate(a, b, VasValueNames.NAME)
);

/**
 * keys plus recommended enum like aliases
 */
const installerSort = {
    // corresponds to sort option values
    'distance-ascending': distanceAsc,
    'distance-descending': distanceDesc,
    'name-ascending': nameAsc,
    'name-descending': nameDesc,
    'priceValue-ascending': priceAsc,
    'priceValue-descending': priceDesc,
    'dropOffDateTimeSeq-ascending': timeAsc,
    'dropOffDateTimeSeq-descending': timeDesc,


    // ENUM style aliases
    DEFAULT: distanceAsc,
    DISTANCE: distanceAsc,
    DISTANCE_DESC: distanceDesc,
    NAME: nameAsc,
    NAME_DESC: nameDesc,
    PRICE: priceAsc,
    PRICE_DESC: priceDesc,
    TIME: timeAsc,
    TIME_DESC: timeDesc
};

module.exports = {
    installerSort
};
