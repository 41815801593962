$ {
    const componentName = 'tires-map';
    const {
        country = 'us',
        language = 'en',
        selectedInstaller = {},
        model = [] // vasForm
    } = input;

    const css = name => `tires-map__${name}`;
    const at = item => item instanceof Array ? item.map(at).join('. ') : ((item && item.accessibilityText) || '');
}
<div class=css('root')>
    <div
        key="google-map"
        class=css('google-map')
        no-update
    >
    </div>
    <div
        key="installer-list"
        class=css('installer-list')
    >
        <for|installer, index| of=model>
            $ {
                const num = (index + 1).toLocaleString(language);
                const {
                    paramValue: id,
                    vasValues: {
                        distance,
                        latitude,
                        longitude,
                        name,
                        postalCode,
                        price
                    } = {},
                    accessoryLabels,
                    secondaryLabels,
                    label,
                    selected,
                } = installer;
                // todo: create accessible marker label
                const accessibilityText = at([label, accessoryLabels]);
                const select = selected || (selectedInstaller && selectedInstaller.paramValue === id);
            }
            <div key=id class=[css('marker'), select ? css('marker--selected') : '']>
                <input
                    class=css('input')
                    id:scoped=id
                    on-change('handleInstallerClick', installer)
                    type="radio"
                    name:scoped="map"
                    checked=select
                />
                <label
                    class=css('container')
                    for:scoped=id
                    title=accessibilityText
                    aria-label=accessibilityText
                >
                    <div class=css('num')>${num}</div>
                    <div class=css('label')>
                        <ux-textualdisplay model=label />
                    </div>
                        <for|value, index| of=(accessoryLabels || [])>
                            <div class=css(`accessory-label-${index}`) title=value.accessibilityText>
                                <ux-textualdisplay model=value />
                            </div>
                        </for>
                </label>
            </div>
        </for>
    </div>
</div>
