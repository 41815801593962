$ const { get } = require("@ebay/retriever");

$ const {
    model = [],
    language,
    selectedInstaller = {},
    showDrawer,
    installerForm = {}
 } = input;

<div class='installer-list'>
  <if(installerForm && showDrawer)>
      $ const installer = model && model[0];
      <drawer
          a11y-close-text=installerForm.accessibilityText
          a11y-minimize-text=installerForm.minimizeA11lyText
          a11y-maximize-text=installerForm.maximizeA11lyText
          open=showDrawer
          on-close('emit', 'close-drawer', { location: false })>
          <@body>
            <installer-card
              select=true
              model=installer />
          </@body>
          <@footer>
              <if(installerForm.chooseInstaller)>
                  <ebay-button
                      priority="primary"
                      aria-label=get(installerForm, 'chooseInstaller.action.accessibilityText', '')
                      on-click('emit', 'installer-click', installer, true, get(installerForm, 'chooseInstaller.action', {}))>
                      <ux-textspans model=get(installerForm, 'chooseInstaller.textSpans', []) />
                  </ebay-button>
              </if>
          </@footer>
      </drawer>
  </if>
  <else>
      <ebay-filter-menu  type='radio' on-change('handleChange')>
        <for|vasItem, index| of=model>
          $ {
            const {
              paramValue,
              accessoryLabel,
              disabled,
              label,
              secondaryLabel,
              selected,
              tertiaryLabel,
            } = vasItem;
            const num = (index + 1).toLocaleString(language);
            const select = (selected || (selectedInstaller && selectedInstaller.paramValue === paramValue));
          }
          <@item checked=select disabled=disabled>
            <installer-card
              number=num
              select=select
              key=`installer-card-${paramValue}`
              model=vasItem />
          </@item>
        </for>
      </ebay-filter-menu>
      <div hidden='true' aria-hidden='true'>
        <svg>
          <symbol viewbox="0 0 18 18" id="icon-radio-checked-18">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M9 0a9 9 0 1 0 0 18A9 9 0 0 0 9 0Zm0 17A8 8 0 1 1 9 1a8 8 0 0 1 0 16Zm5-8A5 5 0 1 1 4 9a5 5 0 0 1 10 0Z"></path>
          </symbol>
          <symbol viewbox="0 0 18 18" id="icon-radio-unchecked-18">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M9 18A9 9 0 1 1 9 0a9 9 0 0 1 0 18ZM9 1a8 8 0 1 0 0 16A8 8 0 0 0 9 1Z"></path>
          </symbol>
        </svg>
      </div>
  </else>
</div>
