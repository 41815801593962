$ {
    const {
        count
    } = input;
    const skeletonCount = count || 3;   
}
<div class="loader">
       <for|i| from=1 to=skeletonCount step=1>
             <div class="loader__list">&nbsp;</div>
       </for>
</div>
