$ const componentName = 'installer-card';
$ const css = (name) => `${componentName}__${name}`;
$ const {
    model: {
        label,
        secondaryLabels,
        accessoryLabels,
        tertiaryLabel, // Used for Slot name like Morning.
        tertiaryLabels // Used for more description of the slot and It can be more than one line and font and color is different than above.
    } = {},
    number,
    select
} = input;

<span class=componentName>
    <span class=css('columns')>
        <span>
            <span class=css('name')>
                <if(number)>
                    <span class=css('index')>${number}. </span>
                </if>
                <ux-textualdisplay model=label/>
            </span>
            <for|item| of=(secondaryLabels || [])>
                <span class=css('address')>
                    <ux-textualdisplay model=item/>
                </span>
            </for>
        </span>
        <span class=css('accessory-label')>
            <for|item| of=(accessoryLabels || [])>
                <ux-textualdisplay model=item/>
            </for>
        </span>
    </span>
    <if(select && tertiaryLabel)>
        <span class=css('hours')>
            <ux-textualdisplay model=tertiaryLabel/>
        </span>
    </if>
    <if(tertiaryLabels)>
        <for|item| of=(tertiaryLabels || [])>
            <span class=css('dropoff__date-time')>
                <ux-textualdisplay model=item/>
            </span>
        </for>
    </if>
</span>
